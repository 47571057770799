<template>
  <div class="award">
    <p>
      <span style="color: rgb(255, 0, 0); font-size: 15px;"
        >元宝比例1比1000,250充值可以，熊猫，乔峰！500直接超级嗜血一觉龙！&nbsp;
        最低30,100的倍数</span
      ><br />
    </p>
    <p>
      <br />
    </p>
    <p>
      <strong
        ><span style="font-size: 15px;"
          >每日(24.00清零)充值到达以下金额可以领取：</span
        ></strong
      >
    </p>
    <p>
      <br />
    </p>
    <p>
      <span style="color: rgb(255, 0, 0);"
        ><strong><span style="font-size: 15px;">30元</span></strong></span
      ><span style="font-size: 15px;"
        >：灵魂碎片.青*5/铸纹血玉*1/蓝田玉令*1/地煞强化精华*10</span
      >
    </p>
    <p>
      <span style="font-size: 15px;"><br /></span>
    </p>
    <p>
      <span style="color: rgb(255, 0, 0);"
        ><strong><span style="font-size: 15px;">100元</span></strong></span
      ><span style="font-size: 15px;"
        >：灵魂碎片.青*10/铸纹血玉*2/蓝田玉令*2/天罡强化精华*10</span
      >
    </p>
    <p>
      <span style="font-size: 15px;"><br /></span>
    </p>
    <p>
      <span style="color: rgb(255, 0, 0);"
        ><strong><span style="font-size: 15px;">300元</span></strong></span
      ><span style="font-size: 15px;"
        >：铸纹血玉*3/蓝田玉令*3/天罡强化精华*15/一箱宝珠*30/暗器修炼石*10</span
      >
    </p>
    <p>
      <span style="font-size: 15px;"><br /></span>
    </p>
    <p>
      <span style="color: rgb(255, 0, 0);"
        ><strong><span style="font-size: 15px;">500元</span></strong></span
      ><span style="font-size: 15px;"
        >:&nbsp;
        &nbsp;妖虎元神*2/天罡强化精华*15/灵魂碎片.赤*5/天罡强化精华*20/一箱宝珠*30/暗器修炼石*15</span
      >
    </p>
    <p>
      <span style="font-size: 15px;"><br /></span>
    </p>
    <p>
      <span style="color: rgb(255, 0, 0);"
        ><strong><span style="font-size: 15px;">1000元</span></strong></span
      ><span style="font-size: 15px;"
        >：&nbsp; 宝石提升符*1 / 女娲石*10 / 魑魅诀*3 / 一箱宝珠*40 /
        绝世藏宝图*20 /</span
      >
    </p>
    <p>
      <span style="font-size: 15px;"><br /></span>
    </p>
    <p>
      <span style="color: rgb(255, 0, 0);"
        ><strong><span style="font-size: 15px;">1500元</span></strong></span
      ><span style="font-size: 15px;"
        >: 灵魂碎片·赤*10 / 宝石提升符*1 / 妖虎元神*5 / 天机诀*5 / 一箱宝珠*50
        /</span
      >
    </p>
    <p>
      <span style="font-size: 15px;"><br /></span>
    </p>
    <p>
      <span style="color: rgb(255, 0, 0);"
        ><strong><span style="font-size: 15px;">2000元</span></strong></span
      ><span style="font-size: 15px;"
        >:&nbsp; 长生灵魄*2 / 宝石提升符*2 / 灵魂碎片·赤*20 / 暗器修炼石*30 /
        九天灵珀*30 /</span
      >
    </p>
    <p>
      <span style="font-size: 15px;"><br /></span>
    </p>
    <p>
      <span style="color: rgb(255, 0, 0);"
        ><strong><span style="font-size: 15px;">2500元</span></strong></span
      ><span style="font-size: 15px;"
        >:&nbsp; 灵魂碎片·赤*25 / 进化诀*3 / 妖虎元神*10 / 雕龙玉令*2 /
        重楼之锋碎片*1 /</span
      >
    </p>
    <p>
      <span style="font-size: 15px;"><br /></span>
    </p>
    <p>
      <span style="color: rgb(255, 0, 0);"
        ><strong><span style="font-size: 15px;">3000元</span></strong></span
      ><span style="font-size: 15px;"
        >：妖虎元神*12 / 宝石提升符*3 / 长生灵魄*3 / 玄凤元神*12 / 麒麟元神*5
        /</span
      >
    </p>
    <p>
      <span style="font-size: 15px;"><br /></span>
    </p>
    <p>
      <span style="color: rgb(255, 0, 0);"
        ><strong><span style="font-size: 15px;">3500元</span></strong></span
      ><span style="font-size: 15px;"
        >：宝石提升符*3 / 玄凤元神*15 / 妖虎元神*15 / 嗜血*33 / 麒麟元神*10
        /</span
      >
    </p>
    <p>
      <span style="font-size: 15px;"><br /></span>
    </p>
    <p>
      <span style="color: rgb(255, 0, 0);"
        ><strong><span style="font-size: 15px;">4000元</span></strong></span
      ><span style="font-size: 15px;"
        >:&nbsp; 重楼之鳞碎片*1 / 玄凤元神*20 / 妖虎元神*20 / 80级保星符*5 /
        麒麟元神*15 /</span
      >
    </p>
    <p>
      <span style="font-size: 15px;"><br /></span>
    </p>
    <p>
      <span style="color: rgb(255, 0, 0);"
        ><strong><span style="font-size: 15px;">5000元</span></strong></span
      ><span style="font-size: 15px;"
        >：重楼之锋碎片*1 / 玄凤元神*30 / 妖虎元神*30 / 龙魂元神*30 /
        麒麟元神*20 /</span
      >
    </p>
    <p>
      <span style="font-size: 15px;"><br /></span>
    </p>
    <p>
      <span style="color: rgb(255, 0, 0);"
        ><strong><span style="font-size: 15px;">6000元</span></strong></span
      ><span style="font-size: 15px;"
        >：铸纹血玉*20 / 麒麟元神*25 / 龙魂元神*40 / 玄凤元神*40 / 宝石提升符*5
        /</span
      >
    </p>
    <p>
      <span style="font-size: 15px;"><br /></span>
    </p>
    <p>
      <span style="color: rgb(255, 0, 0);"
        ><strong><span style="font-size: 15px;">7000元</span></strong></span
      ><span style="font-size: 15px;"
        >：重楼之鳞碎片*1 / 妖虎元神*45 / 80级保星符*10 / 90级保星符*10 /
        铸纹血玉*30 /</span
      >
    </p>
    <p>
      <span style="font-size: 15px;"><br /></span>
    </p>
    <p>
      <span style="color: rgb(255, 0, 0);"
        ><strong><span style="font-size: 15px;">8000元</span></strong></span
      ><span style="font-size: 15px;"
        >:&nbsp; 麒麟元神*30 / 90级保星符*20 / 天命神符*1 / 灵魂碎片·橙*50 /
        鲲鹏玉令*8 /</span
      >
    </p>
    <p>
      <br />
    </p>
    <p>
      <strong><span style="font-size: 15px;">累计充值奖励：</span></strong>
    </p>
    <p>
      <span style="font-size: 15px;"><br /></span>
    </p>
    <p>
      <span style="color: rgb(255, 0, 0);"
        ><strong><span style="font-size: 15px;">100元</span></strong></span
      ><span style="font-size: 15px;"
        >： 嗜血*5 / 紫髓玉令*5 / 新莽神符*5 / 龙魂元神*30 / 绝世藏宝图*10
        /</span
      >
    </p>
    <p>
      <span style="font-size: 15px;"><br /></span>
    </p>
    <p>
      <span style="color: rgb(255, 0, 0);"
        ><strong><span style="font-size: 15px;">300元</span></strong></span
      ><span style="font-size: 15px;"
        >：嗜血*10 / 紫髓玉令*10 / 新莽神符*10 / 龙魂元神*50 / 宝石提升符*1
        /</span
      >
    </p>
    <p>
      <span style="font-size: 15px;"><br /></span>
    </p>
    <p>
      <span style="color: rgb(255, 0, 0);"
        ><strong><span style="font-size: 15px;">500元</span></strong></span
      ><span style="font-size: 15px;"
        >：嗜血*18 / 紫髓玉令*15 / 新莽神符*15 / 龙魂元神*80 / 宝石提升符*1
        /</span
      >
    </p>
    <p>
      <span style="font-size: 15px;"><br /></span>
    </p>
    <p>
      <span style="color: rgb(255, 0, 0);"
        ><strong><span style="font-size: 15px;">1000元</span></strong></span
      ><span style="font-size: 15px;"
        >：蚩尤剑*1 / 灵魂碎片·青*80 / 龙魂元神*100 / 宝石提升符*3 / 九天灵珀*80
        /</span
      >
    </p>
    <p>
      <span style="font-size: 15px;"><br /></span>
    </p>
    <p>
      <span style="color: rgb(255, 0, 0);"
        ><strong><span style="font-size: 15px;">2000元</span></strong></span
      ><span style="font-size: 15px;"
        >：雕龙玉令*5 / 灵魂碎片·青*100 / 龙魂元神*125 / 宝石提升符*4 /
        雀翎金梅*1 /</span
      >
    </p>
    <p>
      <span style="font-size: 15px;"><br /></span>
    </p>
    <p>
      <span style="color: rgb(255, 0, 0);"
        ><strong><span style="font-size: 15px;">3000元</span></strong></span
      ><span style="font-size: 15px;"
        >：雕龙玉令*8 / 灵魂碎片·赤*30 / 麒麟元神*10 / 宝石提升符*5 /
        玄凤元神*50 /</span
      >
    </p>
    <p>
      <span style="color: rgb(255, 0, 0);"
        ><strong><span style="font-size: 15px;">5000元</span></strong></span
      ><span style="font-size: 15px;"
        >：雕龙玉令*15 / 铸纹血玉*35 / 灵魂碎片·赤*35 / 麒麟元神*40 /
        宝石提升符*10 /</span
      >
    </p>
    <p>
      <span style="font-size: 15px;"><br /></span>
    </p>
    <p>
      <span style="color: rgb(255, 0, 0);"
        ><strong><span style="font-size: 15px;">8000元</span></strong></span
      ><span style="font-size: 15px;"
        >：雕龙玉令*20 / 铸纹血玉*40 / 灵魂碎片·赤*40 / 麒麟元神*50 /
        宝石提升符*15 /</span
      >
    </p>
    <p>
      <span style="font-size: 15px;"><br /></span>
    </p>
    <p>
      <span style="color: rgb(255, 0, 0);"
        ><strong><span style="font-size: 15px;">12000元</span></strong></span
      ><span style="font-size: 15px;"
        >：雕龙玉令*25 / 铸纹血玉*50 / 麒麟元神*60 / 宝石提升符*15 /
        大漠金戈马*1 /</span
      >
    </p>
    <p>
      <span style="font-size: 15px;"><br /></span>
    </p>
    <p>
      <span style="color: rgb(255, 0, 0);"
        ><strong><span style="font-size: 15px;">15000元</span></strong></span
      ><span style="font-size: 15px;"
        >：雕龙玉令*30 / 铸纹血玉*75 / 灵魂碎片·橙*75 / 麒麟元神*70 /
        宝石提升符*15 /</span
      >
    </p>
    <p>
      <span style="font-size: 15px;"><br /></span>
    </p>
    <p>
      <span style="color: rgb(255, 0, 0);"
        ><strong><span style="font-size: 15px;">20000元</span></strong></span
      ><span style="font-size: 15px;"
        >: 铸纹血玉*100 / 灵魂碎片·橙*100 / 麒麟元神*80 / 宝石提升符*15 /
        妖虎元神*100 /</span
      >
    </p>
    <p>
      <span style="font-size: 15px;"><br /></span>
    </p>
    <p>
      <span style="color: rgb(255, 0, 0);"
        ><strong><span style="font-size: 15px;">25000元</span></strong></span
      ><span style="font-size: 15px;"
        >: 铸纹血玉*125 / 灵魂碎片·橙*125 / 麒麟元神*90 / 宝石提升符*20 /
        重楼之锋碎片*1 /</span
      >
    </p>
    <p>
      <span style="font-size: 15px;"><br /></span>
    </p>
    <p>
      <span style="color: rgb(255, 0, 0);"
        ><strong><span style="font-size: 15px;">30000元</span></strong></span
      ><span style="font-size: 15px;"
        >：铸纹血玉*150 / 灵魂碎片·橙*150 / 麒麟元神*100 / 宝石提升符*20 /
        重楼之鳞碎片*1 /</span
      >
    </p>
    <p>
      <span style="font-size: 15px;"><br /></span>
    </p>
    <p>
      <span style="color: rgb(255, 0, 0);"
        ><strong><span style="font-size: 15px;">35000元</span></strong></span
      ><span style="font-size: 15px;"
        >：铸纹血玉*175 / 灵魂碎片·橙*175 / 宝石提升符*25 / 九天灵珀*150 /
        青玄灵魄*20 /</span
      >
    </p>
    <p>
      <span style="font-size: 15px;"><br /></span>
    </p>
    <p>
      <span style="color: rgb(255, 0, 0);"
        ><strong><span style="font-size: 15px;">39000元</span></strong></span
      ><span style="font-size: 15px;"
        >：铸纹血玉*200 / 宝石提升符*25 / 重楼玉*1 / 鲲鹏玉令*20 / 紫薇灵魄*20
        /</span
      >
    </p>
    <section class="_editor">
      <p>
        <br />
      </p>
    </section>
  </div>
</template>
<script>
export default {
  name: 'Award',
  // metaInfo() {
  //   return {
  //     title: '充值奖励说明',
  //   }
  // },
}
</script>
<style lang="less" scoped>
.award {
  padding: 0 20px;
}
</style>
